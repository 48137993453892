.ex-table--basic th {
  vertical-align: top; }

.ex-table--basic.uk-table-divider > colgroup + tr,
.ex-table--basic.uk-table-divider > colgroup + * tr {
  border-top: none; }

.ex-table--basic.ex-table--tiny td, .ex-table--basic.ex-table--tiny th {
  padding: 4px 8px;
  font-size: 0.75rem; }
  .ex-table--basic.ex-table--tiny td small, .ex-table--basic.ex-table--tiny td .uk-label, .ex-table--basic.ex-table--tiny th small, .ex-table--basic.ex-table--tiny th .uk-label {
    font-size: 0.75rem; }
  .ex-table--basic.ex-table--tiny td .ex-form__element, .ex-table--basic.ex-table--tiny th .ex-form__element {
    height: 18px;
    margin-bottom: 0; }
  .ex-table--basic.ex-table--tiny td .ex-form__dropdown, .ex-table--basic.ex-table--tiny th .ex-form__dropdown {
    height: auto; }
  .ex-table--basic.ex-table--tiny td .ex-dropdown, .ex-table--basic.ex-table--tiny th .ex-dropdown {
    position: relative;
    top: -4px;
    left: -8px; }
    .ex-table--basic.ex-table--tiny td .ex-dropdown .ex-dropdown__trigger, .ex-table--basic.ex-table--tiny th .ex-dropdown .ex-dropdown__trigger {
      font-size: 0.75rem; }

.ex-table--basic.ex-table--no-outer-padding tr > th:first-child,
.ex-table--basic.ex-table--no-outer-padding tr > td:first-child {
  padding-left: 0; }

.ex-table--basic.ex-table--no-outer-padding tr > th:last-child,
.ex-table--basic.ex-table--no-outer-padding tr > td:last-child {
  padding-right: 0; }

.ex-table--data.ex-table--tiny .ReactVirtualized__Table__headerColumn,
.ex-table--data.ex-table--tiny .ReactVirtualized__Table__rowColumn {
  font-size: 0.75rem; }
  .ex-table--data.ex-table--tiny .ReactVirtualized__Table__headerColumn small, .ex-table--data.ex-table--tiny .ReactVirtualized__Table__headerColumn .uk-label,
  .ex-table--data.ex-table--tiny .ReactVirtualized__Table__rowColumn small,
  .ex-table--data.ex-table--tiny .ReactVirtualized__Table__rowColumn .uk-label {
    font-size: 0.75rem; }

.ex-table--data.ex-table--hover .ReactVirtualized__Table__row:not(.ex-table--data__group):hover {
  background: #ffd; }

.ex-table--data.ex-table--divider .ReactVirtualized__Table__row:not(.ex-table--data__group) {
  border-top: 1px solid #e5e5e5; }

.ex-table--data.ex-table--no-outer-padding .ReactVirtualized__Table__headerColumn:first-of-type,
.ex-table--data.ex-table--no-outer-padding .ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0 !important; }

.ex-table--data.ex-table--no-outer-padding .ReactVirtualized__Table__headerColumn:last-of-type,
.ex-table--data.ex-table--no-outer-padding .ReactVirtualized__Table__rowColumn:last-of-type {
  margin-right: 0 !important; }

.ex-table--data.ex-table--sticky .ReactVirtualized__Table__headerRow {
  position: sticky;
  z-index: 1;
  top: 60px;
  background-color: #fff; }

.ex-table--data__group {
  font-size: 0.8125rem;
  color: #aaa; }
  .ex-table--data__group .ReactVirtualized__Table__rowColumn {
    position: relative;
    top: 7px; }

.ex-table--data__placeholder .ReactVirtualized__Table__rowColumn {
  color: #999; }

.ex-table--data__scroll-dummy {
  display: inline-block;
  width: 200px;
  height: 1rem;
  background-color: #eee; }

.ex-table--data .ReactVirtualized__Table__row:nth-child(2n) .ex-table--data__scroll-dummy {
  width: 300px; }

.ex-table--data .ReactVirtualized__Table__row:nth-child(3n) .ex-table--data__scroll-dummy {
  width: 250px; }

.ex-table--data__row--hidden {
  display: none !important; }
  .ex-table--data__row--hidden:first-of-type + .ReactVirtualized__Table__rowColumn {
    margin-left: 10px; }

.ex-table--data [tabindex]:focus {
  outline: none; }

.ex-table--data .ReactVirtualized__Table__headerRow {
  text-align: left;
  vertical-align: bottom;
  font-size: 0.875rem;
  font-weight: normal;
  color: #999; }

.ex-table--data .ReactVirtualized__Table__sortableHeaderColumn:hover {
  color: #666; }

.ex-table--data .ReactVirtualized__Table__sortableHeaderIcon {
  position: relative;
  top: -6px;
  left: 5px; }

.ex-table--data .ReactVirtualized__Table__headerColumn:not(.ReactVirtualized__Table__sortableHeaderColumn) > .ReactVirtualized__Table__sortableHeaderIcon {
  display: none; }
