.ex-export > * {
  text-align: left; }

.ex-export svg {
  width: 0.875rem;
  height: 0.875rem; }

.ex-export .ex-label {
  color: #aaa;
  font-size: 0.6875rem;
  margin-right: 5px !important; }
  .ex-export .ex-label .uk-icon {
    vertical-align: text-bottom; }

.ex-export .ex-dropdown__trigger {
  color: #aaa;
  font-size: 0.6875rem;
  padding: 0; }
  .ex-export .ex-dropdown__trigger.uk-open {
    background-color: transparent;
    border-color: transparent; }
