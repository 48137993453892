.ex-button:not(.uk-icon-button) {
  border-radius: 3px; }

.ex-button__dropdown {
  padding: 0 5px; }
  .ex-button__dropdown .uk-icon.ex-button__icon--left {
    left: 0 !important; }
  .ex-button__dropdown + .uk-dropdown {
    margin-top: 0;
    padding: 5px 10px 10px; }

.ex-button .uk-icon,
.ex-button .ex-icon--material {
  cursor: pointer; }

.ex-button:not(.uk-icon-button) .uk-icon,
.ex-button:not(.uk-icon-button) .ex-icon--material {
  position: relative;
  top: -2px; }
  .ex-button:not(.uk-icon-button) .uk-icon.ex-button__icon--left,
  .ex-button:not(.uk-icon-button) .ex-icon--material.ex-button__icon--left {
    left: -10px; }
  .ex-button:not(.uk-icon-button) .uk-icon.ex-button__icon--right,
  .ex-button:not(.uk-icon-button) .ex-icon--material.ex-button__icon--right {
    left: 10px; }

.ex-button.uk-icon-button {
  cursor: pointer; }
  .ex-button.uk-icon-button:not(.uk-button-default) {
    border: none; }
  .ex-button.uk-icon-button .ex-icon--material {
    font-size: 20px; }

.ex-button.uk-button-large.uk-icon-button .ex-icon--material {
  font-size: 32px; }

.ex-button.uk-button-small .ex-icon--material {
  font-size: 20px; }

.ex-button.uk-button-tiny .uk-icon,
.ex-button.uk-button-tiny .ex-icon--material {
  top: -1px; }
  .ex-button.uk-button-tiny .uk-icon.ex-button__icon--left,
  .ex-button.uk-button-tiny .ex-icon--material.ex-button__icon--left {
    left: -5px; }
  .ex-button.uk-button-tiny .uk-icon.ex-button__icon--right,
  .ex-button.uk-button-tiny .ex-icon--material.ex-button__icon--right {
    left: 5px; }

.ex-button.uk-button-tiny .ex-icon--material {
  font-size: 16px; }

.ex-button.uk-button-tiny.uk-icon-button .ex-icon--material {
  font-size: 11px; }

.ex-button--merge-right {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right-color: #f4f4f4; }

.ex-button--merge-left {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }
