.ex-scrollable {
  transition: background-color .3s ease-out;
  background-color: #fff;
  overflow: auto; }
  .ex-scrollable:hover {
    background-color: #e2e2e2; }
  .ex-scrollable::-webkit-scrollbar {
    width: 10px; }
  .ex-scrollable::-webkit-scrollbar-thumb {
    background-color: inherit;
    border-radius: 10px; }
  .ex-scrollable::-webkit-scrollbar-track {
    background-color: #fff; }
  .ex-scrollable:hover::-webkit-scrollbar-track {
    background-color: #f4f4f4; }
  .ex-scrollable > div {
    padding-right: 20px;
    background-color: #fff;
    min-height: 100%;
    padding-top: 1px;
    padding-bottom: 1px; }
