.ex-dropdown + .ex-dropdown {
  margin-left: 20px; }

.ex-dropdown .uk-card {
  min-width: 200px; }

.ex-dropdown .ex-dropdown__trigger {
  padding: 4px 8px;
  display: block;
  border-radius: 3px;
  text-transform: none; }
  .ex-dropdown .ex-dropdown__trigger.uk-open {
    background: rgba( var(--app-colour-components, 46, 46, 46), 0.2 );
    border-color: rgba( var(--app-colour-components, 46, 46, 46), 0.2 ); }
  .ex-dropdown .ex-dropdown__trigger .ex-dropdown__placeholder {
    color: #aaa;
    font-weight: 300; }
  .ex-dropdown .ex-dropdown__trigger .uk-icon {
    margin-left: 2px;
    position: relative;
    top: -2px; }
  .ex-dropdown .ex-dropdown__trigger .ex-dropdown__placeholder:empty + .uk-icon {
    margin-left: 0; }

.ex-dropdown--with-datepicker .uk-card {
  min-width: inherit; }

.ex-withadvancedselect {
  border: 1px solid #e2e2e2; }
  .ex-withadvancedselect__left {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .ex-withadvancedselect__right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .ex-withadvancedselect__single {
    border-right: 1px solid #e2e2e2; }

.ex-select__empty {
  color: #222;
  font-size: 0.8125rem;
  font-weight: normal;
  text-decoration: none;
  height: 30px;
  margin: 0 !important;
  padding: 4px 20px;
  color: #ccc; }

.ex-select input {
  padding-left: 20px; }

.ex-select li {
  color: #222;
  font-size: 0.8125rem;
  font-weight: normal;
  text-decoration: none;
  height: 30px;
  margin: 0 !important;
  color: #2e2e2e; }
  .ex-select li > div {
    padding: 4px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center; }
  .ex-select li:not(.ex-select__group) {
    cursor: pointer; }
    .ex-select li:not(.ex-select__group).active {
      color: var(--app-colour-override, var(--app-colour)); }
    .ex-select li:not(.ex-select__group):hover, .ex-select li:not(.ex-select__group):focus {
      text-decoration: none;
      background-color: #f4f4f4;
      outline: none; }
  .ex-select li.muted {
    color: #aaa;
    font-weight: normal; }
  .ex-select li.ex-select__group {
    font-size: 0.8125rem;
    color: #aaa; }

.ex-select__clear {
  color: #2e2e2e;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase; }
  .ex-select__clear:not(:first-child) {
    margin-top: 20px; }
  .ex-select__clear + .ex-select__list {
    margin-top: 0 !important; }

.ex-select__list ul {
  padding: 0;
  margin: 0; }

.ex-select__list--virtual:not(:first-child) {
  margin-top: 20px; }

.ex-select__list--virtual .ReactVirtualized__List {
  will-change: auto !important; }
  .ex-select__list--virtual .ReactVirtualized__List:focus {
    outline: none; }

.uk-card > .ex-select {
  margin: 0 -20px; }

.uk-card > .ex-select--filterable {
  margin-top: -20px; }
