.ex-filter__label {
  justify-content: center;
  margin-right: 40px; }

.ex-filter input {
  color: #2e2e2e;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  text-transform: none;
  color: #2e2e2e; }
  .ex-filter input, .ex-filter input:focus, .ex-filter input:hover {
    border: 0;
    border-bottom: 1px solid #f4f4f4;
    text-decoration: none; }

.ex-filter .ex-dropdown .ex-dropdown__trigger {
  white-space: nowrap; }
