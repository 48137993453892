.ex-widget {
  background: #fff;
  box-shadow: 1px 1px 0 rgba( var(--app-colour-components, 46, 46, 46), 0.4 );
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  border-radius: 3px; }
  .ex-widget--muted {
    background: #eee;
    border: 1px solid #e2e2e2;
    box-shadow: none; }
  .ex-widget--sticky {
    position: sticky;
    top: 80px;
    display: flex;
    flex-direction: column; }
    .ex-widget--sticky--fullscreen {
      top: 20px; }
  .ex-widget--on-top {
    z-index: 980; }
  .ex-widget--expand-height {
    height: 1px; }
  .ex-widget--extra-padding {
    padding: 40px; }
  .ex-widget--no-padding {
    padding: 0; }
  .ex-widget__title {
    font-weight: 200; }
    .ex-widget__title__sub {
      color: #999;
      font-size: 1.1875rem;
      padding-left: 10px; }
  .ex-widget > .ex-overflow-menu {
    position: absolute;
    top: 20px;
    right: 20px; }
