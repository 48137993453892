body.ex-loaded--material-icons .ex-icon--material {
  display: initial; }

.ex-icon {
  cursor: auto; }
  .ex-icon--material {
    font-family: "Material Icons", "Fira Sans";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: none;
    line-height: 1;
    vertical-align: middle;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
  .ex-icon__sub, .ex-icon__sup {
    /* Zero out the line-height so that it doesn't
       interfere with the positioning that follows */
    line-height: 0;
    /* Where the magic happens: makes all browsers position
       the sup/sup properly, relative to the surrounding text */
    position: relative;
    /* Note that if you're using Eric Meyer's reset.css, this
       is already set and you can remove this rule */
    vertical-align: baseline; }
  .ex-icon__sup {
    /* Move the superscripted text up */
    top: -.2em;
    font-size: 16px; }
    .ex-icon__sup--empty {
      width: 16px;
      height: 16px; }
    .ex-icon__sup svg {
      width: 16px;
      height: 16px; }
  .ex-icon__sub {
    /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
    bottom: -.3em;
    margin-left: -.4em;
    font-size: 10px; }
    .ex-icon__sub svg {
      width: 10px;
      height: 10px; }
