@media (min-width: 1300px) {
  .ex-form.uk-form-horizontal .uk-form-label {
    text-align: right; } }

.ex-form__toolbar__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  background: repeating-linear-gradient(120deg, transparent 0, #e2e2e2 1px, transparent 2px, transparent 4px); }

.ex-form__toolbar--sticky {
  position: sticky;
  bottom: 20px; }

.ex-form__toolbar .ex-button:not(:last-child) {
  margin-right: 10px; }

.ex-form__toolbar .ex-button.uk-button-default {
  background-color: #fff; }

.ex-form__element--full-width {
  margin-left: 0 !important; }

.ex-form__element--inline {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0 !important; }
  .ex-form__element--inline .uk-form-label {
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
    width: initial;
    text-align: initial;
    float: none; }
  .ex-form__element--inline .uk-form-controls {
    flex-grow: 1;
    margin-left: 0 !important; }

.ex-form__image__preview {
  display: none; }
  .ex-form__image__preview > div {
    display: inline-block;
    border: 1px solid #e2e2e2;
    background-color: #f4f4f4;
    max-width: 128px; }
    .ex-form__image__preview > div img {
      display: block; }
  .ex-form__image__preview--show {
    display: block; }

.ex-form__dropdown {
  display: flex !important;
  height: 40px;
  align-items: center; }

.ex-form__colour .uk-card {
  display: flex;
  justify-content: center; }
