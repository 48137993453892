.ex-footer {
  display: flex;
  padding: 20px; }
  .ex-footer--nav {
    white-space: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline; }
    .ex-footer--nav:hover .ex-footer__copyright > span {
      display: none; }
    .ex-footer--nav:hover .ex-footer__copyright > a {
      display: block !important; }
    .ex-footer--nav .ex-footer__copyright {
      text-align: left !important;
      display: inline-block;
      vertical-align: top; }
  .ex-footer__copyright {
    display: block;
    width: 200px; }
  .ex-footer__icon {
    cursor: pointer; }

.ex-main + .ex-footer {
  justify-content: center; }
