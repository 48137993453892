.ex-login-form {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  border: 1px solid #e2e2e2; }
  .ex-login-form__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .ex-login-form__cancel {
    margin-top: 20px; }
  .ex-login-form .ex-loading-overlay + .ex-form__element {
    margin-top: 0 !important; }
