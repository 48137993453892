/* ROW_HEIGHT in VirtualTable.js */
.ex-advanced-select {
  overflow: hidden; }
  .ex-advanced-select .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important; }
  .ex-advanced-select .ReactVirtualized__Table__headerRow {
    border-bottom: 1px solid #e2e2e2; }
  .ex-advanced-select .ReactVirtualized__Grid {
    padding-top: 20px; }
  .ex-advanced-select--with-toolbar .ReactVirtualized__Grid {
    padding-top: 10px; }
  .ex-advanced-select .ReactVirtualized__Table__headerColumn:first-of-type,
  .ex-advanced-select .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 20px; }
  .ex-advanced-select .ReactVirtualized__Table__headerColumn,
  .ex-advanced-select .ReactVirtualized__Table__rowColumn {
    margin-right: 20px; }
  .ex-advanced-select--height-auto,
  .ex-advanced-select--height-auto .ex-advanced_select__table {
    height: 100%; }
  .ex-advanced-select__header {
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e2e2e2; }
  .ex-advanced-select__row--header {
    text-align: left;
    font-size: 0.875rem;
    font-weight: normal;
    color: #999; }
    .ex-advanced-select__row--header span {
      vertical-align: bottom; }
  .ex-advanced-select__row .ex-filter .uk-input {
    padding-left: 0;
    border-bottom: 0;
    text-transform: uppercase;
    font-size: 0.875rem;
    border-radius: 5px;
    transition: padding-left .1s ease-out; }
    .ex-advanced-select__row .ex-filter .uk-input:hover, .ex-advanced-select__row .ex-filter .uk-input:focus {
      padding-left: 10px;
      background-color: #f4f4f4; }
  .ex-advanced-select__row:not(.ReactVirtualized__Table__headerRow):not(.ex-advanced-select__group):not(.ex-advanced-select__toolbar) {
    cursor: pointer;
    z-index: 10; }
    .ex-advanced-select__row:not(.ReactVirtualized__Table__headerRow):not(.ex-advanced-select__group):not(.ex-advanced-select__toolbar).ex-advanced-select__row--active .ex-advanced-select__cell {
      color: var(--app-colour-override, var(--app-colour)); }
    .ex-advanced-select__row:not(.ReactVirtualized__Table__headerRow):not(.ex-advanced-select__group):not(.ex-advanced-select__toolbar):hover, .ex-advanced-select__row:not(.ReactVirtualized__Table__headerRow):not(.ex-advanced-select__group):not(.ex-advanced-select__toolbar):focus {
      outline: none;
      background-color: #f4f4f4; }
  .ex-advanced-select__row.ex-advanced-select__group {
    font-size: 0.8125rem;
    color: #aaa; }
  .ex-advanced-select__row.ex-advanced-select__placeholder {
    color: #ccc;
    font-size: 0.8125rem; }
  .ex-advanced-select__row.ex-advanced-select__toolbar {
    margin-left: 20px;
    overflow: visible !important; }
  .ex-advanced-select__cell {
    color: #222;
    font-size: 0.8125rem;
    font-weight: normal;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center; }
    .ex-advanced-select__cell:hover, .ex-advanced-select__cell:focus {
      text-decoration: none; }
    .ex-advanced-select__cell--grouped {
      position: relative;
      padding-left: 1rem; }
      .ex-advanced-select__cell--grouped .ex-advanced-select__spacer {
        width: 0; }
    .ex-advanced-select__cell--expandable {
      position: relative;
      padding-left: 1.3125rem; }
      .ex-advanced-select__cell--expandable .ex-advanced-select__spacer {
        width: 1.5rem; }
    .ex-advanced-select__cell--grouped.ex-advanced-select__cell--expandable {
      padding-left: 0; }
    .ex-advanced-select__cell .ex-advanced-select__spacers {
      display: flex; }
    .ex-advanced-select__cell .ex-advanced-select__spacer {
      height: 27px; }
      .ex-advanced-select__cell .ex-advanced-select__spacer::before, .ex-advanced-select__cell .ex-advanced-select__spacer::after {
        display: block;
        content: "";
        position: relative;
        border: 0 solid transparent; }
      .ex-advanced-select__cell .ex-advanced-select__spacer::before {
        top: 0;
        left: -.85rem;
        height: 100%;
        width: 1rem;
        border-left-width: 1px; }
      .ex-advanced-select__cell .ex-advanced-select__spacer:last-child::after {
        top: -15px;
        left: -.85rem;
        height: 0;
        width: .5rem;
        border-top-width: 1px; }
    .ex-advanced-select__cell .ex-advanced-select__expand-collapse {
      position: relative;
      display: flex;
      align-items: center;
      left: -1.3125rem;
      width: 0;
      height: 27px;
      color: #438dc7; }
      .ex-advanced-select__cell .ex-advanced-select__expand-collapse .uk-icon {
        cursor: pointer;
        padding: 3px; }
        .ex-advanced-select__cell .ex-advanced-select__expand-collapse .uk-icon:hover, .ex-advanced-select__cell .ex-advanced-select__expand-collapse .uk-icon:focus {
          background-color: #e2e2e2; }
  .ex-advanced-select mark {
    background-color: inherit;
    color: inherit; }
  .ex-advanced-select--highlight-searches mark {
    background-color: #fff68c; }
  .ex-advanced-select__row--last-child .ex-advanced-select__spacer:last-child::before {
    height: 13px; }
  .ex-advanced-select__row--last-child .ex-advanced-select__spacer:last-child::after {
    top: 0; }
  .ex-advanced-select .ReactVirtualized__Table__rowColumn:not(:first-child) .ex-advanced-select__cell--grouped {
    padding-left: 0; }
    .ex-advanced-select .ReactVirtualized__Table__rowColumn:not(:first-child) .ex-advanced-select__cell--grouped .ex-advanced-select__spacers {
      display: none; }
  .ex-advanced-select [tabindex]:focus {
    outline: none; }
  .ex-advanced-select ul {
    margin: 0; }
  .ex-advanced-select-symbols__overlay {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10%;
    left: 0;
    height: 90%;
    width: 100%;
    padding: 0;
    background: white;
    opacity: .9;
    transition: opacity .5s;
    z-index: 1; }
  .ex-advanced-select-symbols__message {
    text-align: center;
    margin-top: 20%;
    opacity: 1.0;
    z-index: 1;
    width: 70%; }
  .ex-advanced-select-symbols__icon {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.2em; }
    .ex-advanced-select-symbols__icon--margin {
      margin-left: 0.5em; }

body > .ex-advanced-select__sortable--dragged [role=gridcell] {
  padding-left: 10px; }
