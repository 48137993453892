.ex-collapser {
  font-family: inherit;
  height: 30px;
  border: none;
  outline: none;
  padding: 0 15px;
  cursor: pointer;
  background-color: transparent; }
  .ex-collapser .ex-icon {
    cursor: pointer; }
